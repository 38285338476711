import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/',
      name: 'home',
      component: () => import('@/views/Home.vue'),
      meta: {
        requiresAuth: false,
        pageTitle: 'Home',
        breadcrumb: [
          {
            text: 'Home',
            active: true,
          },
        ],
      },
    },
    {
      path: '/list-coin',
      name: 'list-coin',
      component: () => import('@/views/ListCoin.vue'),
      meta: {
        requiresAuth: false,
        pageTitle: 'List Coin',
        breadcrumb: [
          {
            text: 'List Coin',
            active: true,
          },
        ],
      },
    },
    {
      path: '/contact-us',
      name: 'contact-us',
      component: () => import('@/views/ContactUs.vue'),
      meta: {
        requiresAuth: false,
        pageTitle: 'Contact Us',
        breadcrumb: [
          {
            text: 'Contact Us',
            active: true,
          },
        ],
      },
    },
    
  ],
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

function is_authenticated() {
  const token = localStorage.getItem("token");
  if (token == "null" || token == null || token == "") {
    return false;
  } else {
    return true;
  }
}
/**
 * Router Authentication Guard
 */
router.beforeEach((to, from, next) => {
  const withoutAuth = [ "home", ];
  if (withoutAuth.includes(to.name)) {
    next();
  }
  else if (!to.meta.requiresAuth) {
    is_authenticated() ? next(to.name) : next();
  } else {
    is_authenticated() ? next() : next({ name: "login" });
  }
});

export default router
